import styled from 'styled-components'
import { Link } from 'gatsby'
import React from 'react'

const Section = styled.section`
  padding: 5rem 2.5rem;
  background-color: #fff;
  color: #000;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    max-width: 1100px;
  }
`

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 960px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    max-width: 1100px;
  }
`

const H2 = styled.h2`
  font-size: 1.375rem;
`

const Separator = styled.hr`
  width: 4rem;
  height: 3px;
  background-color: #3567b7;
  border: none;
  border-radius: 50px;
  margin: 0.5rem 1.5rem 1.5rem;
`

const Button = styled((props) => <Link {...props} />)`
  color: #fff;
  font-size: 0.8rem;
  font-weight: 300;
  text-decoration: none;
  width: max-content;
  height: max-content;
  padding: 0.9rem 1.2rem;
  border: none;
  border-radius: 0 20px;
  background-image: linear-gradient(60deg, #3567b7, rgba(53, 103, 183, 0.84));
  letter-spacing: 0.1em;
  position: relative;
  z-index: 1;
  transition: all 0.1s;

  &:before {
    border-radius: inherit;
    background-image: linear-gradient(
      270deg,
      #3567b7,
      rgba(53, 103, 183, 0.84)
    );
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -1;
    transition: opacity 0.1s;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    opacity: 0.85;
    &:before {
      opacity: 1;
    }
  }
`

export { Section, Container, ContainerRow, H2, Separator, Button }
